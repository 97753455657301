const allSlides = {
  ru: [
    // {
    //   id: 1,
    //   img: avon,
    //   text: 'sliderAvon',
    //   name: '',
    //   status: '',
    //   result1: '3 месяца',
    //   result2: 'от идеи до запуска продукта на рынок'
    // },
    {
      id: 1,
      img: 'clients/metro.png',
      avatar: 'clients/marina-metro.png',
      text: 'sliderMetro',
      name: 'sliderMetroName',
      status: 'sliderMetroStatus',
      link: '/blog/metro-cash-carry-ukraine-starts-issuing-digital-customer-cards-instantly-in-viber',
      result1: '',
      result2: ''
    },
    {
      id: 2,
      img: 'clients/tascombank-logo.png',
      avatar: 'clients/alexander-nikolenko-tascombank.jpg',
      text: 'sliderTas',
      name: 'sliderTasName',
      status: 'sliderTasStatus',
      link: '/blog/tascombank-corezoid',
      result1: '',
      result2: '',
    },
    {
      id: 3,
      img: 'clients/lanit-logo.png',
      avatar: 'clients/lanit-avatar.png',
      text: 'sliderLanit',
      name: 'sliderLanitName',
      status: 'sliderLanitStatus',
      link: '/blog/lanit-bpm-middleware-strategic-partnership',
      result1: '',
      result2: ''
    },
    {
      id: 4,
      img: 'clients/spacebank-logo.png',
      avatar: 'clients/lasha-spacebank.png',
      text: 'sliderSpaceBank',
      name: 'sliderSpaceBankName',
      status: 'sliderSpaceBankStatus',
      link: '/blog/space-bank-built-on-corezoid',
      result1: '',
      result2: ''
    },
    {
      id: 5,
      img: 'clients/mambu.png',
      avatar: 'clients/goldin-mambu.jpg',
      text: 'sliderMambu',
      name: 'sliderMambuName',
      status: 'sliderMambuStatus',
      link: '/blog/mambu-corezoid-cloud-state-machine',
      result1: '',
      result2: ''
    },
    {
      id: 6,
      img: 'clients/pumb.png',
      avatar: 'clients/pumb-begunov.jpg',
      text: 'sliderPumb',
      name: 'sliderPumbName',
      status: 'sliderPumbStatus',
      link: '/blog/pumb-begunov',
      result1: '',
      result2: ''
    },
    {
      id: 7,
      img: 'clients/glovo.png',
      avatar: 'clients/glovo-rasnovsky.jpg',
      text: 'sliderGlovo',
      name: 'sliderGlovoName',
      status: 'sliderGlovoStatus',
      link: '/blog/glovo-rasnovsky-corezoid',
      result1: '',
      result2: ''
    },
    {
      id: 8,
      img: 'clients/blue-finance.png',
      avatar: 'clients/julio-gomez-1.jpg',
      text: 'sliderBlueFinance',
      name: 'sliderBlueFinanceName',
      status: 'sliderBlueFinanceStatus',
      link: '/blog/blue-finance-julio-gomez-corezoid',
      result1: '',
      result2: ''
    },
  ],
  en: [
    {
      id: 1,
      img: 'clients/metro.png',
      avatar: 'clients/marina-metro.png',
      text: 'sliderMetro',
      name: 'sliderMetroName',
      status: 'sliderMetroStatus',
      link: '/blog/metro-cash-carry-ukraine-starts-issuing-digital-customer-cards-instantly-in-viber',
      result1: '',
      result2: ''
    },
    {
      id: 2,
      img: 'clients/tascombank-logo.png',
      avatar: 'clients/alexander-nikolenko-tascombank.jpg',
      text: 'sliderTas',
      name: 'sliderTasName',
      status: 'sliderTasStatus',
      link: '/blog/tascombank-corezoid',
      result1: '',
      result2: '',
    },
    {
      id: 3,
      img: 'clients/mambu.png',
      avatar: 'clients/goldin-mambu.jpg',
      text: 'sliderMambu',
      name: 'sliderMambuName',
      status: 'sliderMambuStatus',
      link: '/blog/mambu-corezoid-cloud-state-machine',
      result1: '',
      result2: ''
    },
    {
      id: 4,
      img: 'clients/spacebank-logo.png',
      avatar: 'clients/lasha-spacebank.png',
      text: 'sliderSpaceBank',
      name: 'sliderSpaceBankName',
      status: 'sliderSpaceBankStatus',
      link: '/blog/space-bank-built-on-corezoid',
      result1: '',
      result2: ''
    },
    {
      id: 5,
      img: 'clients/lanit-logo.png',
      avatar: 'clients/lanit-avatar.png',
      text: 'sliderLanit',
      name: 'sliderLanitName',
      status: 'sliderLanitStatus',
      link: '/blog/lanit-bpm-middleware-strategic-partnership',
      result1: '',
      result2: ''
    },
    {
      id: 6,
      img: 'clients/pumb.png',
      avatar: 'clients/pumb-begunov.jpg',
      text: 'sliderPumb',
      name: 'sliderPumbName',
      status: 'sliderPumbStatus',
      link: '/blog/pumb-begunov',
      result1: '',
      result2: ''
    },
    {
      id: 7,
      img: 'clients/glovo.png',
      avatar: 'clients/glovo-rasnovsky.jpg',
      text: 'sliderGlovo',
      name: 'sliderGlovoName',
      status: 'sliderGlovoStatus',
      link: '/blog/glovo-rasnovsky-corezoid',
      result1: '',
      result2: ''
    },
    {
      id: 8,
      img: 'clients/blue-finance.png',
      avatar: 'clients/julio-gomez-1.jpg',
      text: 'sliderBlueFinance',
      name: 'sliderBlueFinanceName',
      status: 'sliderBlueFinanceStatus',
      link: '/blog/blue-finance-julio-gomez-corezoid',
      result1: '',
      result2: ''
    },
  ]
};

export default allSlides;
