import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'mw-style-react';
import './Demo.scss';
// import MainBlock from '../MainBlock/MainBlock';
import Image from '../Image/Image';
import Link from '../../i18n/Link';

const host = process.env.GATSBY_HOST;
const signup_url = `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`;


class Demo extends PureComponent {
  render() {
    const { intl, event } = this.props;
    // const index = 0;
    // const host = process.env.GATSBY_HOST;
    // const item = {
    //   title,
    //   image: 'corezoid.png',
    //   btn: {
    //     title: buttonText,
    //     subText: 'registrationSubText',
    //     link: host,
    //     event: buttonEvent
    //   }
    // };

    return (
      <div className="main__demo">
        {/* <MainBlock*/}
          {/* key={index}*/}
          {/* index={index}*/}
          {/* item={item}*/}
          {/* background={this.props.background}*/}
        {/* />*/}
        <div className="main__demo__wrap" style={{background: this.props.background}}>
          <Image className="main__demo__image" file={'corezoid.png'} />
          <h2 className="main__demo__text">{intl.formatMessage({id: 'postDemoTitle'})}</h2>
          <Link
            to={signup_url}
            className="main__demo__btn__container"
            event={event || 'digital_core_start'}
            id="sign_up_digital_core"
          >
            <Button
              className="button__default main__demo__btn"
              label={intl.formatMessage({id: 'registration'})}
            />
            <p>{intl.formatMessage({id: 'registrationSubText'})}</p>
          </Link>
        </div>
      </div>
    );
  }
}

Demo.defaultProps = {
  title: 'mainContentCoreTitle',
  buttonText: 'mainContentCoreBtnTitle',
  buttonEvent: 'Get Started',
  event: 'digital_core_start',
};

Demo.propTypes = {
  background: PropTypes.string,
  event: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Demo);

