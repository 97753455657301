import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {Button} from 'mw-style-react';
import cn from 'classnames';
import './HeaderPage.scss';
import PayButton from '../PayButton';
import { Link } from '../../i18n';
import Utils from '../../utils/utils';
import Image from '../Image/Image';

class HeaderPage extends PureComponent {
  render() {
    const { data, intl } = this.props;

    return (
      <div className="headerPage__wrapper">
        <div className="headerPage">
          <div className="col-7 headerPage__header__text">
            <div className="headerPage__title">
              <h1>
                {intl.formatMessage({id: data.title})}
              </h1>
            </div>
            <div className="headerPage__subTitle">
              <span
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.subTitle}))
                }}
              />
            </div>
            <div className={cn('headerPage__buttons', { headerPage__buttons__reverse: data.buttonsReverse })} style={{display: 'none'}}>
              {data.button1 &&
                <Link
                  to={data.button1.link}
                  className="headerPage__btn__container"
                  event={data.button1.event}
                  id={data.button1.id}
                >
                  <Button
                    className={'button__default headerPage__btn'}
                    label={intl.formatMessage({id: data.button1.text})}
                  />
                  {data.button1.subText &&
                    <p>{intl.formatMessage({id: data.button1.subText})}</p>
                  }
                </Link>
              }
              {data.button2 &&
                <Link
                  to={data.button2.link}
                  event={data.button2.event}
                  id={data.button2.id}
                >
                  <Button
                    className={'button__secondary headerPage__btn'}
                    label={intl.formatMessage({id: data.button2.text})}
                  />
                </Link>
              }
              {data.buttonPay &&
                <PayButton
                  styleClasses={cn({ button__default: data.buttonsReverse, button__secondary: !data.buttonsReverse }, 'headerPage__btn', 'headerPage__btn__pay')}
                  label={intl.formatMessage({id: data.buttonPay.text})}
                />
              }
            </div>
          </div>
          {data.image &&
            <div className="col-5">
              <div className="headerPage__image">
                <Image className="headerPage__image__container" file={data.image}/>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}


HeaderPage.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(HeaderPage);

