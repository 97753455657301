import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'mw-style-react';
import UtilsApi from '../../utils/api';

const host = process.env.GATSBY_HOST;

class PayButton extends PureComponent {
  state = {
    loading: false
  };

  loading(loading) {
    this.setState({
      loading,
    });
  }

  componentDidMount() {
    let key = '';
    if (
      process.env.GATSBY_HOST.indexOf('dev') !== -1 ||
      process.env.GATSBY_HOST.indexOf('pre') !== -1
    ) {
      key = 'pk_test_xvUkvKX7lQSty7oV9OoSCDQZ';
    } else {
      key = 'pk_live_rHVS21A6ssJRlwXdiptwGhwY';
    }

    this.stripeHandler = StripeCheckout.configure({
      key,
      closed: () => {
        // callback на закрытие окна stripe
        this.setState({
          loading: false
        });
      },
    });
  }

  pay(data) {
    this.loading(true);

    UtilsApi.isAuth(res => {
      if (!res.login) {
        document.location.href = `${host}/auth2/single_account?redirect_uri=${encodeURIComponent(document.location)}`;
      } else {
        this.openStripeCheckout({
          ...data,
          login: res.login,
        });
      }
    });
  }

  openStripeCheckout({ pay, login, value }) {
    this.stripeHandler.open({
      name: value.toUpperCase(),
      amount: pay,
      email: login,
      description: 'Individual education program',
      opened: () => this.loading(false),
    });
  }

  render() {
    const { label, styleClasses } = this.props;
    return (<Button
      className={styleClasses}
      label={label}
      onClick={() =>
        this.pay({
          pay: 1500 * 100,
          value: 'workshop',
        })
    }
    />);
  }
}

PayButton.propTypes = {
  label: PropTypes.string.isRequired,
  styleClasses: PropTypes.string,
};

export default PayButton;
