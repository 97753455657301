import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const code = {
  header: {
    title: 'awsHeaderTitle',
    subTitle: 'awsHeaderText',
    image: 'aws/aws.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'CODE_get_started',
      id: 'sign_up_code'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'CODE_request_demo',
      id: 'request_demo_code'
    }
  },
  content: [
    {
      title: 'awsRoute53',
      subTitle: 'awsRoute53Description',
      image: 'aws/route_53.png',
    },
    {
      title: 'awsElasticLoadBalancing',
      subTitle: 'awsElasticLoadBalancingDescription',
      image: 'aws/ec_2.png',
    },
    {
      title: 'awsCloudTrial',
      subTitle: 'awsCloudTrailDescription',
      image: 'aws/cloud_trail.png',
    },
    {
      title: 'awsCloudFormation',
      subTitle: 'awsCloudFormationDescription',
      image: 'aws/cloud_formation.png',
    },
    {
      title: 'awsAutoScaling',
      subTitle: 'awsAutoScalingDescription',
      image: 'aws/auto_scaling.png',
    }
  ],
  demo: {
    event: 'CODE_digital_core_start'
  }
};


const Code = (props) => (
  <Layout {...props}>
    <Landing
      data={code}
      // page={'code'}
    />
  </Layout>
);


export default withIntl(Code);

