import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Demo from '../../components/Demo';
import './Landing.scss';
import MainBlock from '../../components/MainBlock/MainBlock';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Slider from '../../components/Slider/Slider';
import LandingNavigator from './components/LandingNavigator';

class Landing extends PureComponent {
  render() {
    const { data, page } = this.props;
    const background = data.content.length % 2 ? '#f6f9fc' : '#fff';

    return (
      <section className="landing">
        <HeaderPage data={data.header}/>
        {
          data.content.map((item, index) => (
            <MainBlock
              key={index}
              index={index}
              item={item}
            />
          ))
        }
        <Slider background={background}/>
        <Demo event={data.demo.event}/>
        <LandingNavigator page={page}/>
      </section>
    );
  }
}


Landing.propTypes = {
  data: PropTypes.object,
  page: PropTypes.string
};

export default injectIntl(Landing);

