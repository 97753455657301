import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { Link } from '../../i18n';
import './Slider.scss';
import Utils from '../../utils/utils';
import arrow from '../../images/arrow.svg';
import Image from '../Image/Image';
import allSlides from '../../constants/slides';

class Slider extends PureComponent {
  constructor(props) {
    super(props);
    const renderSlides = allSlides[props.intl.locale];

    this.state = {
      activeSlide: renderSlides[Math.ceil(renderSlides.length / 2) - 1].id,
      slides: renderSlides,
      slideHeight: 0
    };
  }

  componentDidMount() {
    this.calcHeight();
  }

  calcHeight() {
    if (this.slide.clientHeight !== this.state.slideHeight) {
      this.setState({
        slideHeight: this.slide.clientHeight
      });
    }
  }

  renderList(activeSlide) {
    return this.state.slides.map(q => (
      <div
        key={q.id}
        className={cn('slider__item', {active: q.id === activeSlide})}
        onClick={() => this.changeSlide(q.id)}
      />
    ));
  }

  renderSlide(slide, intl) {
    return (
      <div
        key={slide.id}
        className="slider__slide"
        onClick={() => this.changeSlide(slide.id)}
      >
        <div className="slider__slide__left">
          <div className="slider__slide__logo">
            <Image file={slide.img} />
          </div>
          <div className="slider__slide__quotes__top">
            <Image file={'quotes.png'} />
          </div>
          <span
            className="slider__slide__content"
            dangerouslySetInnerHTML={{
              __html: Utils.bbCodeToHtml(intl.formatMessage({id: slide.text}))
            }}
          />
          <div className="slider__slide__quotes__bottom">
            <Image file={'quotes.png'} />
          </div>
        </div>
        <div className="slider__slide__bottom">
          <div className="slider__slide__separator"/>
          <div className="slider__slide__bottom__client">
            <Image className="slider__slide__avatar" file={slide.avatar} />
            <div>
              <div className="slider__slide__name">
                {slide.name &&
                  intl.formatMessage({ id: slide.name })
                },
              </div>
              <div className="slider__slide__status">
                {slide.status &&
                  intl.formatMessage({id: slide.status})
                }
              </div>
            </div>
          </div>
          {slide.link &&
            <Link to={slide.link} className="slider__slide__link">
              <span>{intl.formatMessage({id: 'sliderButton'})}</span>
              <img src={arrow} alt="More"/>
            </Link>
          }
        </div>
        {/* <div className="col-5 slider__slide__result">*/}
          {/* <div className="slider__slide__result__title">*/}
            {/* {intl.formatMessage({id: 'sliderResult'})}*/}
          {/* </div>*/}
          {/* <div className="slider__slide__separator"/>*/}
          {/* <div className="slider__slide__result__bottom">*/}
            {/* <div className="slider__slide__result__textTop">*/}
              {/* {slide.result1}*/}
            {/* </div>*/}
            {/* <div className="slider__slide__result__text">*/}
              {/* {slide.result2}*/}
            {/* </div>*/}
          {/* </div>*/}
        {/* </div>*/}
      </div>
    );
  }

  changeSlide(key) {
    this.setState({
      activeSlide: key
    });
  }

  clickLeftSlide() {
    const {activeSlide} = this.state;
    if (activeSlide <= 1) return;
    this.changeSlide(activeSlide - 1);
  }

  clickRightSlide() {
    const {activeSlide} = this.state;
    if (activeSlide === this.state.slides.length) return;
    this.changeSlide(activeSlide + 1);
  }

  render() {
    const { activeSlide, slideHeight, slides } = this.state;
    const { intl, background } = this.props;
    const slideIndex = slides.findIndex(s => s.id === activeSlide);
    const slideWidth = 720 + 100;
    const sliderCorrectLeft = 74;
    const width = slides.length * slideWidth;
    const left = (-slideIndex * slideWidth) + sliderCorrectLeft;

    return (
      <div style={{background}} className="slider__wrapper">
        <div
          className="slider__container__left"
          style={{
            width: `calc((100vw - ${slideWidth}px) / 2)`,
            background: `linear-gradient(to right, ${background}, rgba(255, 255, 255, 0))`
          }}
          onClick={() => this.clickLeftSlide()}
        />
        <div
          className="slider__container__right"
          style={{
            width: `calc((100vw - ${slideWidth}px) / 2)`,
            background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${background})`
          }}
          onClick={() => this.clickRightSlide()}
        />
        <div className="slider">
          <div className="slider__title">
            {intl.formatMessage({id: 'sliderTitle'})}
          </div>
          <div className="slider__container" style={{height: slideHeight}}>
            <div
              className="slider__sliders"
              ref={(el) => (this.slide = el)}
              style={{width, left}}
            >
              {slides.map(slide => (
                this.renderSlide(slide, intl)
              ))}
            </div>
          </div>
          <div className="slider__item__container">
            {this.renderList(activeSlide)}
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  intl: PropTypes.object,
  background: PropTypes.string,
};

export default injectIntl(Slider);
