import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Button,
} from 'mw-style-react';
import cn from 'classnames';
import './MainBlock.scss';
import { Link } from '../../i18n';
import Utils from '../../utils/utils';
import Image from '../Image/Image';

class MainBlock extends PureComponent {
  render() {
    const { item, index, intl, background } = this.props;
    const buttonType = 'button__default';
    // if (item.btn && (item.btn.link === '/knowledge' || item.btn.link === '/events')) {
    //   buttonType = 'button__secondary';
    // }

    if (index % 2) {
      return (
        <div className="mainBlock" style={{background}}>
          <div className="grid-wrapper mainBlock__wrap">
            <div className="col-6 mainBlock__content">
              <h2
                className="mainBlock__content__title"
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.title}))
                }}
              />
              {item.subTitle &&
                <span
                  className="mainBlock__content__subTitle"
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.subTitle}))
                  }}
                />
              }
              <div>
                {item.btn &&
                  <Link
                    to={item.btn.link}
                    className="mainBlock__content__btn__container"
                    event={item.btn.event}
                  >
                    <Button
                      className={cn(buttonType, 'mainBlock__content__btn')}
                      label={intl.formatMessage({id: item.btn.title})}
                    />
                    {item.btn.subText &&
                      <p>{intl.formatMessage({id: item.btn.subText})}</p>
                    }
                  </Link>
                }
              </div>
            </div>
            <div className="col-6 mainBlock__img__wrapper">
              {item.image &&
                <Image className="mainBlock__img" file={item.image} />
              }
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="mainBlock right" style={{background}}>
        <div className="grid-wrapper mainBlock__wrap">
          <div className="col-6 mainBlock__img__wrapper">
            {item.image &&
              <Image className="mainBlock__img" file={item.image} />
            }
          </div>
          {/* <div className="col-6 mainBlock__img">*/}
            {/* {item.image && <img src={item.image} alt={'blockImg'}/>}*/}
          {/* </div>*/}
          <div className="col-6 mainBlock__content">
            <h2
              className="mainBlock__content__title"
              dangerouslySetInnerHTML={{
                __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.title}))
              }}
            />
            {item.subTitle &&
              <span
                className="mainBlock__content__subTitle"
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.subTitle}))
                }}
              />
            }
            <div>
              {item.btn &&
                <Link
                  to={item.btn.link}
                  className="mainBlock__content__btn__container"
                  event={item.btn.event}
                >
                  <Button
                    className={cn(buttonType, 'mainBlock__content__btn')}
                    label={intl.formatMessage({id: item.btn.title})}
                    // onClick={() => this.changeViewLiveDemo()}
                  />
                  {item.btn.subText &&
                    <p>{intl.formatMessage({id: item.btn.subText})}</p>
                  }
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MainBlock.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.object,
  index: PropTypes.number,
  background: PropTypes.string,
};


export default injectIntl(MainBlock);
