import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import './LandingNavigator.scss';
import { Link } from '../../../../i18n';

import {
  softwareNavigator,
  apiNavigator,
  codeNavigator,
  processesNavigator,
  iotNavigator,
  communicationNavigator,
  // eventsNavigator,
  // knowledgeNavigator,
  peopleNavigator,
  cloudNavigator,
} from '../../../../images';

const nav = [
  {
    key: 'software',
    value: 'headerSolutionsSoftware',
    image: softwareNavigator
  },
  {
    key: 'manage-api',
    value: 'headerSolutionsApi',
    image: apiNavigator
  },
  {
    key: 'code',
    value: 'headerSolutionsCode',
    image: codeNavigator
  },
  {
    key: 'processes',
    value: 'headerSolutionsProcesses',
    image: processesNavigator
  },
  {
    key: 'iot',
    value: 'headerSolutionsIot',
    image: iotNavigator
  },
  {
    key: 'communication',
    value: 'headerSolutionsCommunication',
    image: communicationNavigator
  },
  // {
  //   key: 'events',
  //   value: 'headerSolutionsEvents',
  //   image: eventsNavigator
  // },
  // {
  //   key: 'knowledge',
  //   value: 'headerSolutionsKnowledge',
  //   image: knowledgeNavigator
  // },
  {
    key: 'people',
    value: 'headerSolutionsPeople',
    image: peopleNavigator
  },
  {
    key: 'cloud',
    value: 'headerSolutionsCloud',
    image: cloudNavigator
  },
];

const LandingNavigator = (props) => (
  <div className="landingNavigator">
    <div className="landingNavigator__wrapper">
      <div>
        {nav.map((item, index) => (
          <Link
            key={index}
            to={`/${item.key}`}
            className={cn('landingNavigator__item', {active: props.page === item.key})}
          >
            <img src={item.image} alt={item.key} />
            {/* <div className="landingNavigator__item__text">
              {props.intl.formatMessage({id: item.value})}
            </div> */}
          </Link>
        ))}
      </div>
    </div>
  </div>
);

LandingNavigator.propTypes = {
  // intl: PropTypes.object,
  page: PropTypes.string,
};

export default injectIntl(LandingNavigator);

